import React from 'react'


// The HtmlStringParser divides one long string into paragraphs.  Prepare the string by placing * at the start of each paragraph. Don't use spaces between paragraphs.  


// Example htmlString: This is the first paragraph text.*This is the second paragraph text.




export default function StringToParaParser(string){

	if(!string.includes("*")){
		return <p>{string}</p>
	}else{
		let len = string.length
		let indexes = []

		//find starting index of all paras

		for (let i = 0; i < len; i++) {
			if (string[i] === "*"){
				indexes.push(i)
			}
		}

		// separate the string into paragraphs. 

		let paras = indexes.map((index, i) => {
			const start = index + 1
			// the final index will be the value of the next item in the indexes array OR if there are no more items, the length of string
			const end = i + 1? indexes[i + 1] : len

			return <p key ={i}>{string.slice(start, end)}</p>

		})
		return <>{paras}</>
	}
}
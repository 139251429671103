import React from 'react'
import data from '../novellas_data'
// import { Link } from "react-router-dom"
import { HashLink as Link} from "react-router-hash-link"

export default function NovellaCovers (){
	
	const covers = data.map(item => {
		const sectionId = item.sectionId
		const url = `/#${sectionId}`
		const ariaLabel = `Read about ${item.title}`

		return (
			<div className="container--novella-image">

				<picture>
					<source 
						srcSet={process.env.PUBLIC_URL + item.imgWebP}
						type="image/webp"
						className="novella" />
					<img 
						key = {item.id}
						className="novella" 
						src={process.env.PUBLIC_URL + item.img}
						alt={item.imgAlt}
					/>
				</picture>
				<Link  
					to={url}
					className="link-image"
					ariaLabel={ariaLabel}
					
				>
					<div 		
						className="container--link-image"
					></div>
				</Link>
			</div>
		)
	})

	

	return (
		<div className="container--novellas flex-row space-between">
			{covers}
		</div>
	)
}
import React from 'react'
import { useRouteError, Link } from "react-router-dom";


export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    // <div className='align-child-center'>
    <div className='container--page'>
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
        <p>If the above is mumbo jumbo, just go back to the </p>
        <Link 
          to="/" end
          className="link-text--green "
          // style = {btnStyles}
          >HOME page.
        </Link>
      </div>
    </div>
  );
}
export default [
	{
		id: 1,
		sectionId: "trs",
		className: "container--book-card-background container--book-card-background-hallux",
		img: "/images/recalcitrantSavior.jpg",
		imgWebP: "/images/recalcitrantSavior.webp",
		imgAlt: "Glitzy but damaged high heels",
		imgCreditText: "Photo by Alex Hudson on Unsplash",
		imgCreditUrl: "https://unsplash.com/@aliffhassan91?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",

		// Photo by <a href="https://unsplash.com/@aliffhassan91?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Alex Hudson</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
  
		// Photo by <a href="https://unsplash.com/@jonathanborba?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jonathan Borba</a> on <a href="https://unsplash.com/s/photos/shoe?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
  
		title: "The Recalcitrant Savior",
		bannerText: "null",
		type: "Short story",
		amazonDotCom: "null",
		amazonPolly: "null",
		writingSales: true, 
		writingSalesAHref:"https://looseleaf.pub/product/gristle/",
		description: "",
		storyIntro: "*Vice President Samwell Park never wanted to run the country. But when his husband (and President of the USA) slips off his high heels and hits his head on live television the nation is thrown into chaos. To make matters worse, a baffling bioterrorist attack strikes, leaving Samwell struggling to mop up a monumental mess.",
		moreAHref: "",
		moreLink: "",
		hash: "/shop#shop-TRS"

	},
	{
		id: 2,
		sectionId: "abw",
		className: "container--book-card-background container--book-card-background-hallux",
		img: "/images/burdock.jpg",
		imgWebP: "/images/burdock.webp",
		imgAlt: "Old botanical drawing of burdock",
		imgCreditText: "",
		imgCreditUrl: "",
		title: "A Burr in the Woods ",
		bannerText: "null",
		type: "Short story",
		amazonDotCom: "null",
		amazonPolly: "null",
		writingSales: false,
		writingSalesAHref:"",
		description: "",
		storyIntro: "*Pemmican Point Senior’s Community is being stalked by a prickly menace, one that does far worse than threatening personal safety: it lowers resale values. *Jessica Cusper is determined to get to the bottom of it, even if she has to risk the bear stalking the weedy wastelands encroaching her dream home.",	
		moreAHref: "https://zeroinputagriculture.wordpress.com/2022/07/01/brisk-fiction-a-burr-in-the-woods/",
		moreLink: "",
		hash: ""
	},
	{
		id: 3,
		sectionId: "ffe",
		className: "container--book-card-background container--book-card-background-hallux",
		img: "/images/fireEaters.jpg",
		imgWebP: "/images/fireEaters.webp",
		imgAlt: "Blurred figures circling a fire in the dark",
		imgCreditText: "Photo by Elia Pellegrini on Unsplash",
		imgCreditUrl: "https://unsplash.com/@eliapelle",
		title: "Fall of the Fire-Eaters",
		bannerText: "null",
		type: "Short story",
		amazonDotCom: "null",
		amazonPolly: "null",
		writingSales: false,
		writingSalesAHref:"",
		description: "",
		storyIntro: "*On a distant future earth a new tribe claims the night, unaided by the destructive power of fire. During their annual migration they cross paths with an ancient people, the fire-eaters, destroying the edges of their territory. Can two inexperienced brothers help their tribe defend their homeland?",
		moreAHref: "https://zeroinputagriculture.wordpress.com/2022/04/04/brisk-fiction-fall-of-the-fire-eaters/",
		moreLink: "",
		hash: ""
	}
]
import React from 'react'
// import { Link } from "react-router-dom"
import { HashLink as Link} from "react-router-hash-link"
import data from '../novellas_data'
import NovellaCard from "./NovellaCard"
import NovellaCovers from './NovellaCovers'
import ButtonBuyHashLink from './ButtonBuyHashLink'

export default function OurVitreousWomb (){

	const novellaCards = data.map(item => {
		return (
			<NovellaCard
				key={item.id}
				item={item}
			/>
		)
		
	})

	// function handleScroll (event) {
	// 	console.log("is scrolling")
	// 	event.target.scrollY ? console.log("scrolling"): console.log("initial position")
	// }

	return (
		// <div className="background-ovw" onScroll={()=> handleScroll}>
		<div className="background-ovw">
		<div className="container--page">
			<div className="grid--ovw-series">
				<div className="rectangle rectangle-banner-left">Out now in paperback and ebook!</div>
				<ButtonBuyHashLink hash="shop#shop-title"/>
				<div className="grid--ovw-series-complete container--discover-series">
					<div className="ovw-cover2">

						<picture>
							<source srcSet={process.env.PUBLIC_URL + "/images/ourVitreousWomb2.webp"}
							type="image/webp" 
							className="ovw-cover"/>
							<img src={process.env.PUBLIC_URL + "/images/ourVitreousWomb2.jpg"} alt="Our Vitreous Womb book cover"
							className="ovw-cover" />
						</picture>
						<Link 
							to="/#about-the-series" 
							aria-label="Read more about the Our Vitreous Womb series">

								<div 		
									className="container--link-image hover-series-page"
								></div>
						</Link>
					</div>
					<h2 id="ovw-series">Our Vitreous Womb</h2>
					<p className="overview">“Our Vitreous Womb” is a series of four hard science-fiction novellas that explores a distant post-industrial future, one where purely biological technology has transformed the planet.</p>

					<NovellaCovers />
				</div>
			</div>
			<div className="ovw-text1 margin-top-between-items">
				<h3 id="about-the-series">About the series</h3>
					<div className="container--containing-box">
						<p className="">Within the smallest continent a boundless world is born&#8230;</p>
					</div>
				<p>Thirty thousand years ago the machine age consumed the world.</p>
				<p>The only remaining resource was biology.</p>
				<p>Now, the battle for the Earth is ongoing. Slave empires rule the largest continents -- bastions of technology from the machine age that stripped the planet millennia ago.
				</p>
				<p>Hidden at the edge of the world a more vibrant people are rising to challenge them: a lineage who grasped the power of life and death to transmute the human heart.		
				</p>
				<p>Among them is Oji Anabasi -- podgy, hairy, sex-obsessed and terrified of death.  In other words, a typical remnant sapiens on a planet long claimed by a new breed of people.</p>
				<p>But what could primitive Oji offer such astonishing people?</p>
				<p>His story, in four parts, is told through the eyes of his wilful mother, bittersweet nursemaid, steadfast wife and ardent advocate.</p>
				<p>One relic human cannot change the course of evolution, but a single mutation might.</p>
			</div>
			{novellaCards}
		</div>
		</div>
	)
}
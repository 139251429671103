import React from 'react'
import ButtonGeneric from './ButtonGeneric'


export default function Blog () {
	return (
		<section className="container--page grid--blog">

			<h2 className="blog-h2">
				Blog
			</h2>

			<div className="blog-text">
				<p>Sign up to my substack to keep track of my podcast interviews, short fiction and more extensive glimpses into my writing life.</p>

			</div>
			
			<ButtonGeneric
				url="https://haldanebdoyle.substack.com"
				text="Take me to the blog →"
			/>

		</section>
	)
}
import React from 'react'

export default function ButtonGeneric (props){
    return(
        <a 
            className="btn--contrast link-text btn--generic" 
            href={props.url}
            target="_blank" 
            rel="noopener noreferrer"
        >{props.text}
        </a>
    )
}

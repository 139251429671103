export default [
	{
		id: 1,
		date: "26 Oct 2023",
		type: "interview",
		organisation: "Page chewing",
		title: "Friday Conversation | Ep 91: Haldane B. Doyle, The Purple BookWyrm, Jose's Amazing Worlds & Paromita",
		url: "https://www.buzzsprout.com/1793148/13853829-friday-conversation-ep-91-haldane-b-doyle-the-purple-bookwyrm-jose-s-amazing-worlds-paromita",
		mediaCardAriaLabel: "Interview in episode 91 of the Friday Conversation series on the Page Chewing website",
		img: "/images/fridayConversation.jpg",
		imgWebP: "images/fridayConversation.webp",
		imgAlt: "Friday Conversation logo",
		hash: ""
	},
	{
		id: 2,
		date: "14 Oct 2023",
		type: "interview",
		organisation: "Psychopath Evolution",
		title:"M.E. Thomas Confessions of a Sociopath Interviews",
		url: "https://www.youtube.com/watch?v=pIt85vXbCYk",
		mediaCardAriaLabel: "Interview on the Psychopath Evolution YouTube Channel in the series called M.E. Thomas Confessions of a Sociopath Interviews",
		img: "/images/psychopathEvolution3.jpg",
		imgWebP: "images/psychopathEvolution3.webp",
		imgAlt: "Haldane B. Doyle and M.E. Thomas in interview",
		hash: ""
	},
	{
		id: 3,
		date: "2023",
		type: "review",
		organisation: "Page chewing",
		title: "Biology what? Untapped potential in Science Fiction",
		url: "https://pagechewing.com/biology-what-untapped-potential-in-science-fiction/",
		mediaCardAriaLabel: "Book review called Biology What? Untapped potential in Science Fiction on the Page Chewing website",
		img: "/images/pageChewing2.jpg",
		imgWebP: "images/pageChewing2.webp",
		imgAlt: "Page Chewing banner",
		hash: ""
	},
	{
		id: 4,
		date: "18 Sep 2023",
		type: "review",
		organisation: "InReview",
		title: "Bittersweet Tales In A Nearly Alien, Far-Future Earth | “Our Vitreous Womb” by Haldane B. Doyle Book Series Review",
		url: "https://inreviewcritics.com/2023/09/18/bittersweet-tales-in-a-nearly-alien-far-future-earth-our-vitreous-womb-by-haldane-b-doyle-book-series-review/",
		mediaCardAriaLabel: "Book review called Bittersweet Tales in a Nearly Alien, Far-Future Earth on the InReview website",
		img: "/images/inReview.png",
		imgWebP: "images/inReview.webp",
		imgAlt: "In Review banner",
		hash: ""
	},
	{
		id: 5,
		date: "2023",
		type: "interview",
		organisation: "EPIC",
		title: "SPSFC 3 Author Interview - Haldane B Doyle",
		url: "https://www.epicindie.net/indie-scifi/spsfc-3-author-interview-a-hrefhttpswwwamazoncoukhaldane-b-doyleeb0brn1zn8zrefdpbylinecontebooks1haldane-b-doylea",
		mediaCardAriaLabel: "Interview called SPSFC 3 Author Interview - Haldane B Doyle on the Epic website",
		img: "/images/epic.jpg",
		imgWebP: "images/epic.webp",
		imgAlt: "Epic logo",
		hash: ""
	},
	{
		id: 6,
		date: "10 Oct 2023",
		type: "interview",
		organisation: "Speculative Fiction Showcase",
		title: "Interview with Haldane B. Doyle, author of Her Unbound Hallux (Our Vitreous Womb, Book 1) ",	
		url: "https://indiespecfic.blogspot.com/2023/10/interview-with-haldane-b-doyle-author.html",
		mediaCardAriaLabel: "Interview with Haldane B. Doyle, author of Her Unbound Hallux on the Speculative Fiction Showcase website",
		img: "/images/specFictionShowcase.jpg",
		imgWebP: "images/specFictionShowcase.webp",
		imgAlt: "Speculative Fiction Showcase banner",
		hash: ""
	}
]

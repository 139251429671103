
import React from 'react'
import {
  // createBrowserRouter,
  // RouterProvider,
  // Route,
  // Routes,
  // BrowserRouter,
  Outlet,
} from "react-router-dom"


export default function WritingIndex (){
	return (
		<>
			<Outlet />
		</>
	)
}
import React from 'react'
import data from '../writing_data'
import dataReadAlong from '../writing_data_readAlong'
import WritingCard from './WritingCard'
import WritingCardReadAlong from './WritingCardReadAlong'
import NovellaCovers from "./NovellaCovers"
import { HashLink as Link} from "react-router-hash-link"
import ButtonBuyHashLink from './ButtonBuyHashLink'

export default function Writing (){


	
	const shortStories = data.map(item => {
		return (
			<WritingCard
				key={item.id}
				item={item}
			/>
		)
	})

	
	const readAlongAudio = dataReadAlong.map(item => {
		return (
			<WritingCardReadAlong
				key={item.id}
				item={item}
			/>
		)
	})
	

	return (

		<div className="container--page container--writing">
			<h2>Writing</h2>
			<nav className="nav-writing flex-column">
				<ul className='flex-row'>
					<li>
						<Link 
							to="#novellas"
							className="nav-writing--link"
							>Novellas
						</Link>
					</li>
					<li>
						<Link 
							to="#shortStories"
							className="nav-writing--link"
							>Short Stories
						</Link>
					</li>
					<li>
						<Link 
							to="#readAlong"
							className="nav-writing--link"
							>Read along with audio
						</Link>
					</li>
				</ul>
			</nav>
			<h3 id="novellas">Novellas</h3>


			<ButtonBuyHashLink hash="/shop#shop-title"/>

			<div className="grid--ovw-series-complete container--discover-series">
				<div className="ovw-cover2">

					<picture>
						<source 
							srcSet={process.env.PUBLIC_URL + "/images/ourVitreousWomb2.webp"}
							type="image/webp"
							className="ovw-cover" 
						/>
						<img 
							src={process.env.PUBLIC_URL + "/images/ourVitreousWomb2.jpg"} 
							alt="Our Vitreous Womb book cover"
							className="ovw-cover"
						/>
					</picture>
					<Link 
						to="/#about-the-series"
						aria-label="Read more about the Our Vitreous Womb series" 
						>

							<div 		
								className="container--link-image hover-writing-page"
							></div>
					</Link>
				</div>
				<h4 id="ovw-series">Our Vitreous Womb</h4>
				<p className="overview">“Our Vitreous Womb” is a series of four hard science-fiction novellas that explores a distant post-industrial future, one where purely biological technology has transformed the planet.</p>

				<NovellaCovers />
			</div>
			<h3 id="shortStories">Short stories</h3>
			{shortStories}	

			<h3 id="readAlong">Read along with audio</h3>
			{readAlongAudio}	
		</div>
	)
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
    // Route,
  } from "react-router-dom";
import ErrorPage from "./error-page"
import OvwOverview from './components/OurVitreousWomb';
import NewsletterSignUpPage from './components/NewsletterSignUpPage';
import NewsletterSignUpForm from './components/NewsletterSignUpForm';
// import NewsletterSignUpThankYou from './components/NewsletterSignUpThankYou';
import Unknown from './components/Unknown'
import About from './components/About'
import Writing from './components/Writing'
import WritingIndex from './components/WritingIndex';
import Blog from './components/Blog';
import Media from './components/Media';
import Shop from './components/Shop'


const router = createBrowserRouter([
    {
        path: "/",
        element:  <App /> ,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <OvwOverview />
            },
            {
                path: "writing",
                exact: true,
                element: <WritingIndex />,
                children: [
                    {
                        // path: '/',
                        index: true,
                        element: <Writing />,
                    },
                ]
            },
            {
                path: "blog",
                element: <Blog />,
            },
            {
                path: "media",
                element: <Media />,
            },
            {
                path: "*",
                element: <Unknown />,
            },
            {
                path: "about",
                element: <About />,
            },
            {
                path: "get-updates",
                element: <NewsletterSignUpPage />,
                children: [
                    {
                        // path: "sign-up",
                        index: true,
                        element: <NewsletterSignUpForm />
                    },
                    // {
                    //     path: "thank-you",
                    //     element: <NewsletterSignUpThankYou />
                    // },

                ]
            },
            {
                path: "shop",
                element: <Shop />,
            },
        ]
    },
])


ReactDOM.createRoot(document.getElementById("root")).render(
      <RouterProvider router={router} />
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

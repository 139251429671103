import React from 'react'

export default function MediaCard (props) {

	return (
		<>
		<div className="media-card">
			
			<a 
			href={props.item.url}
			className="media-link link-text"
			aria-label={props.item.mediaCardAriaLabel}
			target="_blank" 
			rel="noopener noreferrer"
			>
			
				<div className="media-img">
					<picture>
						<source 
							srcSet={process.env.PUBLIC_URL + props.item.imgWebP}
							type="image/webp"
							className="writing--cover" />
						<img 
							className="writing--cover" src={process.env.PUBLIC_URL + props.item.img} 
							alt={props.item.imgAlt} />
					</picture>
				</div>

				<div className="container--media-text">
					<p className="media-type">
						{props.item.type}
					</p>
					
					<h4  
						className="media-organisation"
						id={props.item.id}
					>{props.item.organisation}
					</h4>

					<div className="media-title">
						{props.item.title}
					</div>
				</div>

			</a>

		</div>
		</>
	)
}
import React from 'react'
import authorImageJpg from '../images/author1.jpg'
import authorImageWebp from '../images/author1.webp'
import SocialIcons from './SocialIcons'


export default function About () {
	return (
		<section className="container--page grid--about">
			<picture id="about-image">
				
				<picture>
					<source srcSet={authorImageWebp}
					type="image/webp" className="authorImage"/>
					<img  
					src={authorImageJpg} alt="author holding a goat and looking into the distance" className="authorImage"/>
				</picture>

			</picture>

			<h2 className="about-h2">
				About the author
			</h2>

			<p className="about-subtitle">
				Haldane B. Doyle<br />is the fictional alter-id of<br/>Dr. Shane Simonsen. 
			</p>

			<div className="about-text margin-top-between-items">
				<p>Haldane grew up in subtropical Australia as a biology obsessed nerdling, consorting with blue tongue lizards, breeding mantis shrimps and molesting octopi when he wasn’t cultivating rare succulents and carnivorous plants.
				</p>
				<p>
				He trained as a research biochemist, then made just enough money to retire before middle age to his experimental farm. There he works hard to develop novel and orphan crops that rely on zero inputs, including domesticating a staple tree crop from the Jurassic. He blogs regularly on this work at <a 
					className="link-text--green"
					href="https://zeroinputagriculture.substack.com"
					target="_blank" 
					rel="noopener noreferrer"
				>Zero Input Agriculture</a> on Substack (or <a 
					className="link-text--green" 
					href="http://www.zeroinputagriculture.com"
					target="_blank" 
					rel="noopener noreferrer"
				>see older Zero Input Agriculture posts here</a>) and will write a book about it someday.
				</p>
				<p>
				Apart from farming he finds time to imagine the distant post-industrial future, creating fictional worlds that could quite possibly come to exist (though hopefully with less heart-slicing drama). His style of hard science fiction relies on extensive research into real research.
				</p>
				<p>
				Sci-fi fans are tired of apocalypses. Star-trek futures have slipped out of reach. That just means it’s time to imagine a future that’s both realistic and inspiring instead. 
				</p>
			</div>

			<SocialIcons />
		</section>
	)
}
import React from 'react'

export default function Footer (){
	return (
		// <div className="footer flex-row space-between align-items-center">
		// 	<p>&copy; 2022 Shane Simonsen</p>
		// 	<p>Credits</p>
		// 	{/* <Link to="/about#contact">Contact</Link> */}
		// </div>
		<div className="footer">
			<p>&copy; 2022-2023 Shane Simonsen</p>
			{/* <p>Credits</p> */}
			{/* <Link to="/about#contact">Contact</Link> */}
		</div>
	)
}
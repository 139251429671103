import React from 'react'
import { HashLink as Link} from "react-router-hash-link"

export default function ButtonBuyHashLink (props){
    return(
    
        <Link 
            to={props.hash}
            className="btn--contrast link-text btn--buy btn--buy--hashlink" 
            aria-label="Go to the shop page"
			>Buy now
	    </Link>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'
import {ReactComponent as FacebookIcon } from "../images/socialIcons/square-facebook.svg"
// import {ReactComponent as RedditIcon } from "../images/socialIcons/square-reddit.svg"
import {ReactComponent as TwitterIcon } from "../images/socialIcons/square-twitter.svg"
import {ReactComponent as GoodreadsIcon } from "../images/socialIcons/goodreads.svg"
import {ReactComponent as EmailIcon } from "../images/socialIcons/square-envelope.svg"
import {ReactComponent as SubstackIcon} from "../images/socialIcons/substack-icon.svg"
import {ReactComponent as YoutubeIcon } from "../images/socialIcons/square-youtube.svg"

export default function SocialIcons (){
	return (
		
		<div className="container--social-icons">
			<ul className="flex-row space-between">
				<li><a 
					className="link--social-icon" 
					href="https://www.facebook.com/profile.php?id=100084164985527"
					target="_blank" 
					rel="noopener noreferrer"
					aria-label="Haldane's Facebook page"
					>
						<FacebookIcon 
							className="social-icon"
						/>
				</a></li>
				{/* <li><a 
					className="link--social-icon"
					href="https://www.reddit.com/r/HaldaneBDoyle/"
					target="_blank" 
					rel="noopener noreferrer"
					aria-label="Haldane's Reddit page"
					>
						<RedditIcon 
							className="social-icon"
						/>
				</a></li> */}
				<li><a 
					className="link--social-icon"
					href="https://twitter.com/DoyleHaldane"
					target="_blank" 
					rel="noopener noreferrer"
					aria-label="Haldane's Twitter page"
					>
						<TwitterIcon
							className="social-icon"
						/>
					</a></li>
				<li><a 
					className="link--social-icon"
					href="https://www.goodreads.com/user/show/137976421-haldane-b-doyle"
					target="_blank" 
					rel="noopener noreferrer"
					aria-label="Haldane's Goodreads page"
					>
						<GoodreadsIcon
							className="social-icon"
						/>
				</a></li>
				<li><a 
					className="link--social-icon"
					href="https://haldanebdoyle.substack.com"
					target="_blank" 
					rel="noopener noreferrer"
					aria-label="Haldane's Substack page"
					>
						<SubstackIcon
							className="social-icon--substack"
						/>
				</a></li>
				<li><a 
					className="link--social-icon"
					href="https://www.youtube.com/@haldanebdoyle"
					target="_blank" 
					rel="noopener noreferrer"
					aria-label="Haldane's YouTube channel" 
					>
						<YoutubeIcon
							className="social-icon"
						/>
				</a></li>
				<li><Link 
					className="link--social-icon"
					to="/get-updates"
					aria-label="Email Haldane"
					>
						<EmailIcon
							className="social-icon"
						/>
				</Link></li>
				
			</ul>

		</div>
	)
}
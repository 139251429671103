import React from 'react'
import {
  Outlet,
} from "react-router-dom"

export default function NewsletterSignUpPage () {
	return (
		<div className="container--page newsletter-sign-up-page">
			<Outlet />
		</div>
	)
}
import React, {useState, useRef} from 'react'


export default function NewsletterSignUpForm () {

	const formRef = useRef(null)

	// const scriptUrl = "https://script.google.com/macros/s/AKfycbz7PLBsMPH2cfEiELqQLvRXWyUXX9DH-lPGd8bnVqYpaLQunizJ2sNnTdV6mAJy-nknBQ/exec"

	const scriptUrl = "https://script.google.com/macros/s/AKfycbzrn1TARsksy5nJ52G0xJ0K01z6mmAlCFSzHbN18JdELTkJx-xYHyz6Cm7mQXgBYHL0/exec"

	const [loading, setLoading] = useState(false)


	const [name, setName] = React.useState("")
	const [email, setEmail] = React.useState("")
	// const [freeBook, setFreeBook] = React.useState(false)
	const [msgFromUser, setMsgFromUser] = React.useState("")
	const [message, setMessage] = React.useState("")
	const chars = 300
	const [charsRemaining, setCharsRemaining] = React.useState(chars)

	function validateEmail(emailValue){
		const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
		if(emailValue.match(mailFormat)){
			return true
		}else{
			alert(`That's not a valid email address. Please try again.`)
			return false
		}
	}

	function removeMessage(){
		setMessage("")
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if(!validateEmail(email)){
			console.log("Email is invalid")
		}else{
			setLoading(true)

			fetch (scriptUrl, {
				method: "POST", 
				body: new FormData(formRef.current)
			}).then(res => {
				// console.log(freeBook)

				if (res.status === 200) {
					setName("")
					setEmail("")
					setMsgFromUser("")
					setCharsRemaining(chars)
					// setFreeBook(false)
					setLoading(false)

					setMessage("Welcome to the email list!  You'll receive a message from me soon.")
					setTimeout(removeMessage, 3000)
				}else {
					setMessage("An error occurred.  Please refresh the page and try again")
					setTimeout(removeMessage, 3000)
				}
			}).catch(err => console.log(err))
		}

	}
	
	const inputChangeHandler = (e) => {
		const inputName = e.target.name
		const inputValue = e.target.value

		if(inputName === "name"){
			setName(inputValue)
		}else if(inputName === "email"){
			setEmail(inputValue)
		// }else if(inputName === "freeBook"){
		// 	setFreeBook((prev) => !prev)
		}else {
			setMsgFromUser(inputValue)
			const charsRemaining = chars - inputValue.length
			setCharsRemaining(charsRemaining)
		}
	}


	React.useEffect(function(){
		const modal = document.getElementById("modal-success")
		if(message){
			modal.classList.add("visible")
		}else{
			modal.classList.remove("visible")
		}
	}, [message])
	


	return (

		<div className='sign-up-form flex-column'>
			<div id="modal-success" className="modal-success flex-column"
			>
				<p>Welcome to the email list!
				<br />
				You'll receive a message from me soon.
				</p>
			</div>
			<h2>Subscribe for a monthly email update</h2>
			{/* <p className="h2-subtitle line1">Message me for a personalised reply.</p> */}
			{/* <p className="line2">
				Request an ARC (advanced review copy) of book 1.<br/>
				Suggest aspects of biology you want to see in sci-fi. <br/>
				Or ask Haldane another question.
			</p> */}
			<p>You could also:</p>
			<ul>
				<li>request an ARC (advanced review copy) of book 1</li>
				<li>suggest aspects of biology you want to see in sci-fi</li>
				<li>ask Haldane another question</li>
			</ul>
			<form
				ref={formRef}
				onSubmit={handleSubmit}
				name="google-sheet"
				className='newsletter-sign-up-form'
				method="post"
			>

				{/*  First name */}
				<label 
					htmlFor="name"
					className="label1"
				
				>
					name (required): 
				</label>
				<input 
					type="text"
					value={name} 
					name="name"
					id="name"
					className="input1"
					onChange = {inputChangeHandler}
					required
					aria-required="true"
				/>
				{/* Email field - required */}
				<label 
					htmlFor="email"
					className="label2"
				>
					email (required): 
				</label>
				<input 
					type="text" 
					value={email}
					name="email"
					id="email"
					className="input2 input-validation"
					onChange = {inputChangeHandler}
					required
					aria-required="true"
				/>
				{/*  Free book checkbox */}
				{/* <div className="checkbox">
					<input 
						type="checkbox"
						checked={freeBook} 
						name="freeBook"
						id="freeBook"
						className="input4"
						onChange = {inputChangeHandler}
					/>
					<label 
						htmlFor="freeBook"
						className="label4"
					
					>
						Yes! I want a free advanced review copy of <i>Her Unbound Hallux.</i>
					</label>
				</div> */}
				{/* msgFromUser field - optional */}
				<div className="container--label3">
					<label 
						htmlFor="msgFromUser"
						className="label3"
					>
						your message for the author: 
					</label>
					<p className="chars"> 
						characters remaining: {charsRemaining}
					</p>
				</div>
				<textarea
					value={msgFromUser}
					placeholder='Yes! I would like a review copy of “Her Unbound Hallux”.'
					name="msgFromUser"
					id="msgFromUser"
					maxLength={chars}
					wrap="soft"
					// rows="2"
					// cols="10"
					className="input3"
					onChange = {inputChangeHandler}
				/>
				{/* <p id="message">{message}</p> */}
				<input className="btn--contrast btn--isign-up-submit" type="submit" value={loading ? "Loading..." : "Send"}/> 
			</form>

		</div>
	)
}

// export default function NewsletterSignUpForm () {

// 	const [first_name, setFirst_name] = React.useState("")
// 	const [email, setEmail] = React.useState("")
// 	const [message, setMessage] = React.useState("")

// 	const responseBody = {
// 		first_name: "", 
// 		email: "", 
// 		campaign_token: "QDZud", 
// 		// thankyou_url: "https://www.google.com", 
// 		start_day: "0"
// 	}
	
// 	function validateEmail(emailValue){
// 		//const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
// 		const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
// 		if(emailValue.match(mailFormat)){
// 			return true
// 		}else{
// 			alert(`That's not a valid email address. Please try again.`)
// 			return false
// 		}
// 	}

// 	const onSubmitHandler = async (e) => {
// 		e.preventDefault()
		
// 		if(!validateEmail(email)){
// 			console.log("Email is invalid")

// 		}else {
// 			responseBody.first_name = first_name
// 			responseBody.email = email
// 			console.log(JSON.stringify(responseBody))

// 			//form submission

// 			try {
// 				let res = await(
// 					fetch("https://app.getresponse.com/add_subscriber.html", {
// 						method: "POST",
// 						acceptCharset: "utf-8",
// 						// mode: 'no-cors',

// 						body: JSON.stringify(responseBody),
// 						headers: {
// 							'Content-Type': 'application/json',
// 							// 'Origin': "http://localhost:3000",
// 							'Origin': null
// 						}
// 					})
// 				)
				
// 				let resJson = await res.json()
// 				if (res.status === 200) {
// 					setFirst_name("")
// 					setEmail("")
// 					setMessage("Welcome to the email list!  You'll receive a message from us shortly.")
// 					/* Remove this console.log and resJson variable if not needed*/
// 					console.log(resJson)
// 				}else {
// 					setMessage("An error occurred.  Please refresh the page and try again")
// 				}
// 			}

// 			catch (err) {
// 				console.log(err)
// 			}

// 		}
// 	}



// 	const inputChangeHandler = (e) => {
// 		const inputName = e.target.name
// 		const stateFunction = inputName === "first_name" ? setFirst_name : setEmail

// 		stateFunction(e.target.value)
		

// 	}

// 	return (
// 		<div className='flex-column'>
// 			<p className="line1">Subscribe to our newsletter</p>
// 			<p className="line2">and get a free story!</p>

// 			<form
// 				onSubmit={onSubmitHandler}
// 				className='newsletter-sign-up-form'
// 			>

// 				{/*  First name */}
// 				<label 
// 					htmlFor="first_name"
// 					className="label1"
				
// 				>
// 					first name (required): 
// 				</label>
// 				<input 
// 					type="text"
// 					value={first_name} 
// 					name="first_name"
// 					id="first_name"
// 					className="input1"
// 					onChange = {inputChangeHandler}
// 					required
// 					aria-required="true"
// 				/>
// 				{/* Email field - required */}
// 				<label 
// 					htmlFor="email"
// 					className="label2"
// 				>
// 					email (required): 
// 				</label>
// 				<input 
// 					type="text" 
// 					value={email}
// 					name="email"
// 					id="email"
// 					className="input2"
// 					onChange = {inputChangeHandler}
// 					required
// 					aria-required="true"
// 				/>
// 				<input className="btn--contrast btn--sign-up-submit" type="submit" value="I'm in!"/> 
// 			</form>
// 			<p id="message">{message}</p>

// 		</div>
// 	)
// }


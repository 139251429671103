import React from 'react'
import StringToParaParser from '../utilities/StringToParaParser'
import ButtonBuyHashLink from './ButtonBuyHashLink'

export default function NovellaCard (props) {
	
	const description = StringToParaParser(props.item.description)
	const sectionId = props.item.sectionId

	return (
		<>
		<div className="grid--book-card">

			{ props.item.bannerText && <div className="rectangle rectangle-banner-small banner--novella-card">{props.item.bannerText}</div> }
		
			<div className="container-column--novella-card">
				<picture className="book--cover--parent">
					<source srcSet={process.env.PUBLIC_URL + props.item.imgWebP}
					type="image/webp" 
					className="book--cover"/>
					<img className="book--cover" 
					src={process.env.PUBLIC_URL + props.item.img} alt={props.item.imgAlt} />
				</picture>
				<ButtonBuyHashLink hash="shop#shop-OVW-individual-novellas" />
			</div>

			<div className="book--description">
				
				<h3  
					id={sectionId}
				>{props.item.title}
				</h3>
				{description}
			</div>
			<a href="#top" className="back-to-top link-text--green">Back to top ↑</a>
		</div>
		</>
	)
}


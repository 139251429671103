import React from 'react'

export default function Unknown () {
	return (
		<div className="flex-column align-items-center container--page margin-top-between-items">
			<h2>You seem a bit lost. . .</h2>
			<p>Either the page you're looking for doesn't exist in this universe . . .or we've moved it - sorry!</p> 
			<p>You could double-check for typos in the url, but if that doesn't work you'll just have to visit a different page.</p>
		</div>
	)
}
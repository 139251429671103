export default [

// Text etc for Our Vitreous Womb would be hardcoded to the page or in a separate data.js file
	{
		id: 1,
		bookNumber: "1",
		sectionId: "huh",
		className: "container--book-card-background container--book-card-background-hallux",
		img: "/images/herUnboundHallux2.jpg",
		imgWebP: "/images/herUnboundHallux2.webp",
		imgAlt: "Her Unbound Hallux book cover",
		title: "Her Unbound Hallux",
		bannerText: null,
		buttonBuyText: "Buy book 1",
		buttonBuyUrl: "https://www.amazon.com/dp/B0BTH21DC9",
		buttonBuyUrl2: null,
		buttonBuyUrl3: "https://www.amazon.com/Our-Vitreous-Womb-Book-1-4-ebook/dp/B0C7H9N1K3",
		description: "*Alates protect the lesser people of Nuziran. Secluded at the foot of the Eternal Emperor, they build spiritual energy until his holy touch transforms them for battle.*Miobeth Anabasi is impatient for mother to go to war. Then the mansion will be hers, complete with a talented husband to restore the Anabasi bloodline. But then a mesmerising manservant, a forbidden map and a glimpse at the strange world beyond the walls change Miobeth forever.*Can she fulfil her maternal duty to the Emperor whilst claiming a new life of her own?"
	},
	{
		id: 2,
		bookNumber: "2",
		sectionId: "hls",
		className: "container--book-card-background container--book-card-background-secretions",
		img: "/images/herLethalSecretions2.jpg",
		imgWebP: "/images/herLethalSecretions2.webp",
		imgAlt: "Her Lethal Secretions book cover",
		title: "Her Lethal Secretions",
		bannerText: null,
		buttonBuyText: "Buy book 2",
		buttonBuyUrl: "https://www.amazon.com/dp/B0BTH6LFRR",
		buttonBuyUrl2: null,
		buttonBuyUrl3: "https://www.amazon.com/Our-Vitreous-Womb-Book-1-4-ebook/dp/B0C7H9N1K3",
		description: "*Halian women are bred to be irresistible. Far across the ocean in Peluvana they become lesser wives to the modern humans who rule that wild continent, cementing ties with Ostrala.*Despite being Halian, Lanella isn’t allowed to feel pretty. Her sisters mock her lop-sided chest at every opportunity. Only the loveliest three girls will remain to birth the next generation: an idyllic life of sewing and gossip while eunuchs tend the babies. Competition is fierce and Lanella can only dream of beating her sisters to the ultimate prize in life.*After Lanella displays a potent mixture of desirable and deleterious traits she accepts a final chance to erase her discredit. She was bred to blend in with sapiens. How hard could it be raising two?"
	},
	{
		id: 3,
		bookNumber: "3",
		sectionId: "hpp",
		className: "container--book-card-background container--book-card-background-pupil",
		img: "/images/herPellucidPupil2.jpg",
		imgWebP: "/images/herPellucidPupil2.webp",
		imgAlt: "Her Pellucid Pupil book cover",
		title: "Her Pellucid Pupil",
		bannerText: null,
		buttonBuyText: "Buy book 3",
		buttonBuyUrl: "https://www.amazon.com/dp/B0BTH5W5SJ",
		buttonBuyUrl2: null,
		buttonBuyUrl3: "https://www.amazon.com/Our-Vitreous-Womb-Book-1-4-ebook/dp/B0C7H9N1K3",
		description: "*In the heart of Ostrala, deep underground, a lineage of albino females curate accumulates knowledge: a living library passed from mother to daughter.*Suvita has a remarkable memory and steady hand. She mastered every language on both tongue and fingertip. Yet her body betrayed its duty to bear the next generation. Unprompted, she hastens to cull, to make room for her little sister.*A new life is offered: unlock the secrets of a forgotten past, in distant Port Anodi among the menacing sapiens.*Cut off from her people, can Suvita complete her duty and earn an honourable death?"
	},
	{
		id: 4,
		bookNumber: "4",
		sectionId: "hif",
		className: "container--book-card-background container--book-card-background-fingerprint",
		img: "/images/hisIndelibleFingerprints2.jpg",
		imgWebP: "/images/hisIndelibleFingerprints2.webp",
		imgAlt: "His Indelible Fingerprint book cover",
		title: "His Indelible Fingerprint",
		bannerText: null,
		buttonBuyText: "Buy book 4",
		buttonBuyUrl: "https://www.amazon.com/dp/B0BTH878TW",
		buttonBuyUrl2: null,
		buttonBuyUrl3: "https://www.amazon.com/Our-Vitreous-Womb-Book-1-4-ebook/dp/B0C7H9N1K3",
		description: "*The people of Ostrala are forever one and many. Divided into familial cells, each founded by a selected male, they carpet the continent as an ever-changing superorganism. Castrated males wander the branching paths, envoys circulating vital coordination.*Remus is unusually tall and splendidly striped for a Lampid (a lineage famed for its influential envoys). His incomparable intellect should have ensured his selection to found a new cell. However, a forbidden love affair derails his ambitions.*After a lifetime of drudge work as an envoy in self-imposed celibacy, a spectacular chance to leave a lasting legacy falls into his spidery hands.*Can Remus reconcile his primitive desires with his hunger for renown?"
	}
]

export default [
	{
		id: 1,
		sectionId: "tfo",
		className: "container--book-card-background container--book-card-background-hallux",
		img: "/images/theFermiOrthodox300S.jpg",
		imgWebP: "/images/theFermiOrthodox300S.webp" ,
		imgAlt: "DNA floating in the galaxy inset into a nighttime landscape",
		imgCreditText: "Image by Stories from the Sky SFF",
		imgCreditUrl: "https://www.youtube.com/@storiesfromthesky",
		title: "The Fermi Orthodox",
		bannerText: "null",
		type: "Read-along audio",
		amazonDotCom: "null",
		amazonPolly: "null",
		writingSales: false, 
		writingSalesAHref:"",
		description: "",
		storyIntro: "*An intrepid space scientist and his childhood friend discover an unpalatable secret about the nature of life in the galaxy.",
		moreAHref: "https://www.youtube.com/watch?v=nbir--GuzKU",
		moreLink: ""

	},
	{
		id: 2,
		sectionId: "oss",
		className: "container--book-card-background container--book-card-background-hallux",
		img: "/images/oneSmallStep300.jpg",
		imgWebP: "/images/oneSmallStep300.webp" ,
		imgAlt: "Gas bubbles rising through water inset into a nighttime landscape",
		imgCreditText: "Image by Stories from the Sky SFF",
		imgCreditUrl: "https://www.youtube.com/@storiesfromthesky",
		title: "One small step",
		bannerText: "null",
		type: "Read-along audio",
		amazonDotCom: "null",
		amazonPolly: "null",
		writingSales: false,
		writingSalesAHref:"",
		description: "",
		storyIntro: "*Using cutting edge technology, a pair of researchers explore the hostile surface of a strangely familiar planet.",	
		moreAHref: "https://www.youtube.com/watch?v=2GEoUweUVf4",
		moreLink: ""
	}
]
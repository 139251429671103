import React from 'react'
import { Link } from 'react-router-dom'

export default function (){
	return (
		<div className="writing-sales">
			<p className="writing-sales-title">
				Want to read more?
			</p>	
			<p>
				This story is published in the anthology <i>The Flesh of Your Future Sticks Between My Teeth: Stories from the </i> Gristle <i>Cli-fi Parody Contest.</i>
			</p>

			<p>	<a 
					href="https://looseleaf.pub/product/gristle/"
					className="link-text--green"
					target="_blank" 
					rel="noopener noreferrer"
					aria-label="Buy the anthology that contains The Recalcitrant Savior"
				>Buy the anthology
				</a> or get the story free when you <Link to="/get-updates"
				className="link-text--green"
				>sign up to the email list!
				</Link>
			</p>
			{/* <p>
				Buy the anthology : <a 
					href="https://looseleaf.pub/product/gristle/"
					className="link-text link-text--green"
					target="_blank" 
					rel="noopener noreferrer"
					aria-label="Buy the anthology in the United States"
				>United States</a> | <a 
					href="https://www.amazon.com.au/Flesh-Future-Sticks-Between-Teeth/dp/1950213048/ref=sr_1_1?crid=3QZAETC05UOL3&keywords=the+flesh+of+your+future&qid=1671464212&sprefix=the+flesh+of+your+future%2Caps%2C269&sr=8-1"
					className="link-text link-text--green"
					target="_blank" 
					rel="noopener noreferrer"
					aria-label="Buy the Anthology in Australia"
				>Australia</a> | <a 
					href="https://www.amazon.com/Flesh-Future-Sticks-Between-Teeth/dp/1950213048/ref=sr_1_1?crid=RRI1CNI4E8JM&keywords=the+flesh+of+your+future+sticks+between+my+teeth&qid=1671464121&sprefix=flesh+of+my+future+sti%2Caps%2C159&sr=8-1"
					className="link-text link-text--green"
					target="_blank" 
					rel="noopener noreferrer"
					aria-label="Buy the anthology elsewhere in the world"
				>Everywhere else!</a>

			</p>
			<p>
				Or get the story free when you <Link to="/get-updates"
				className="link-text link-text--green"
				
				>sign up to the email list!</Link>
			</p> */}
		</div>
	)
}
import React from 'react'
import data from '../writing_data'
import novellasData from '../novellas_data'
import ShopCardNovellas from './ShopCardNovellas'
import { HashLink as Link} from "react-router-hash-link"
import writing_data from '../writing_data'


export default function Shop (){

	const individualNovellaCards = novellasData.map(item => {
			return (
				<ShopCardNovellas
					key={item.id}
					item={item}
				/>
			)
	})



	return (

		<div className="container--page container--writing">
			<h2 id="shop-title">Shop</h2>
			
			<h3 id="shopOVW" className="shopH3">Our Vitreous Womb</h3>
			<h4 id="shopOVWCompleteSeries" className="shopH4">Complete series</h4>

			<div className="shop-card">

				<div className='group1'>

					{/* Starts here */}
					<div className="container--cover-shop">

						<picture>
							<source 
								srcSet={process.env.PUBLIC_URL + "/images/ourVitreousWomb2.webp"}
								type="image/webp"
								className="cover-shop" 
							/>
							<img 
								src={process.env.PUBLIC_URL + "/images/ourVitreousWomb2.jpg"} 
								alt="Our Vitreous Womb book cover"
								className="cover-shop"
							/>
						</picture>
						
						<Link 
							to="/#about-the-series"
							aria-label="Read more about the Our Vitreous Womb series" 
							>
								<div 		
									className="container--link-image--shop"
								></div>
						</Link>

					</div>
					{/* Ends here */}

				</div>

				<div className='group2'>
					<h5 className="shopH5">Our Vitreous Womb (novellas 1-4)</h5>
					<h6 id="" className="shopH6">Paperback</h6>
					<a 
						href="https://www.amazon.com/Our-Vitreous-Womb-Book-1-4/dp/0645724343"
						className="link-text--green"
						target="_blank" 
						rel="noopener noreferrer"
						aria-label="Buy the complete Our Vitreous Womb series in paperbook on Amazon"
					>Amazon</a>
					<h6 id="" className="shopH6">eBook</h6>
					<a 
						href="https://www.kobo.com/us/en/ebook/our-vitreous-womb"
						className="link-text--green"
						target="_blank" 
						rel="noopener noreferrer"
						aria-label="Buy the complete Our Vitreous Womb series ebook on Kobo"
					>Kobo</a> <br />
					<a 
						href="https://www.amazon.com/Our-Vitreous-Womb-Book-1-4-ebook/dp/B0C7H9N1K3"
						className="link-text--green"
						target="_blank" 
						rel="noopener noreferrer"
						aria-label="Buy the complete Our Vitreous Womb series ebook on Amazon"
					>Amazon</a>
				</div>

			</div>
				
			<h4 id="shop-OVW-individual-novellas" className="shopH4">Individual novellas</h4>

			<div className="row-wrap">
				{individualNovellaCards}
			</div>
			
			<h3 id="shop-TRS" className="shopH3">The Recalcitrant Savior</h3>
			<div className="shop-card">

				<div className="group1">
					<div className="container--cover-shop" id="TRSCoverMarginBottom">

						<picture>
							<source 
								srcSet={process.env.PUBLIC_URL + data[0].img}
								type="image/webp"
								className="cover-shop" 
							/>
							<img 
								src={process.env.PUBLIC_URL + data[0].imgWebP} 
								alt={data[0].imgAlt}
								className="cover-shop"
							/>
						</picture>
						
						<Link 
							to="/writing#trs"
							aria-label="Read more about the The Recalcitrant Savior" 
							>

								<div 		
									className="container--link-image--shop"
								></div>
						</Link>
					</div>
					{writing_data[0].imgCreditText && <a 
						href={writing_data[0].imgCreditUrl}
						className="img-credit link-text link-text--white-transparent"
						id="shop-img-credit"
						target="_blank" 
						rel="noopener noreferrer"
						>{writing_data[0].imgCreditText}
					</a>}
				</div>

				<div className="group2">
					<h5 className="shopH5">The Recalcitrant Savior</h5>
					<p className="TRSp">
						This story is published in the anthology <i>The Flesh of Your Future Sticks Between My Teeth: Stories from the </i> Gristle <i>Cli-fi Parody Contest.</i>
					</p>

					<h6 id="" className='shopH6'>Paperback</h6>
					<p>	<a 
							href="https://looseleaf.pub/product/gristle/"
							className="link-text--green"
							target="_blank" 
							rel="noopener noreferrer"
							aria-label="Buy the anthology that includes The Recalcitrant Savior"
						>Looseleaf Publishing
						</a>
					</p> 
					<p className="TRSp">or get the story free when you <Link to="/get-updates"
						className="link-text--green"
						>sign up to the email list!
						</Link>
					</p>
				</div>
				

			</div>
		</div>
	)
}
import React from 'react'
import { HashLink as Link} from "react-router-hash-link"

export default function ShopCardNovellas (props) {
    return (
        <>
			<div className="shop-card">

				<div className="group1">
					<div className="container--cover-shop">

						<picture>
							<source 
								srcSet={process.env.PUBLIC_URL + props.item.imgWebP}
								type="image/webp"
								className="cover-shop" 
							/>
							<img 
								src={process.env.PUBLIC_URL + props.item.img} 
								alt={props.item.imgAlt}
								className="cover-shop"
							/>
						</picture>
						
						<Link 
							to={"/#"+props.item.sectionId}
							aria-label={"Read more about" +props.item.title }
							>

								<div 		
									className="container--link-image--shop"
								></div>
						</Link>
					</div>

				</div>

				<div className="group2">
					<h5>{`${props.item.bookNumber}. ${props.item.title}`}</h5>
					<h6 id="" className='shopH5'>eBook</h6>
					<a 
						href={props.item.buttonBuyUrl}
						className='link-text--green'
						target="_blank" 
						rel="noopener noreferrer"
						aria-label={"Buy" +props.item.title + "in ebook format on Amazon."}
					>Amazon</a>
				</div>

			</div>
        </>
    )
}
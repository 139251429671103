import React from 'react'
import data from '../media_data'
import MediaCard from './MediaCard'

export default function Media (){
	
	const mediaCards = data.map(item => {
		return (
			<MediaCard
				key={item.id}
				item={item}
			/>
		)
	})
	

	return (

		<div className="container--page container--writing">
			<h2>Media</h2>

			<p className="media-intro">All the latest interviews and book reviews are right here!</p>
			{mediaCards}	
			<a href="#top" className="back-to-top media-back-to-top link-text--green">Back to top ↑</a>
		</div>
	)
}
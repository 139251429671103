import React from 'react'
// import { Link } from "react-router-dom"
import StringToParaParser from '../utilities/StringToParaParser'
import WritingSales from './WritingSales'

export default function WritingCardSky (props) {
	
	const storyIntro = StringToParaParser(props.item.storyIntro)
	const sectionId = props.item.sectionId

	return (
		<>
		<div className="grid--writing-card">
			<div className="writing-img-plus-credit">
				
				<picture>
					<source 
						srcSet={process.env.PUBLIC_URL + props.item.imgWebP}
						type="image/webp"
						className="writing--cover" />
					<img 
						className="writing--cover" src={process.env.PUBLIC_URL + props.item.img} 
						alt={props.item.imgAlt} />
				</picture>

				{props.item.imgCreditText && <a 
					href={props.item.imgCreditUrl}
					className="img-credit link-text link-text--white-transparent"
					target="_blank" 
					rel="noopener noreferrer"
					>{props.item.imgCreditText}
				</a>}
			</div>

			<a 
				href={props.item.writingSales? props.item.writingSalesAHref : props.item.moreAHref}
				className="story-link"
				target="_blank" 
				rel="noopener noreferrer"
			>
				<h4  
					className="writing-card-title"
					id={sectionId}
				>{props.item.title}
				</h4>
			</a>
			{props.item.writingSales && <WritingSales />}
			<div className="story-intro">
				{storyIntro}
			</div>
			{props.item.moreAHref && <a 
				href={props.item.moreAHref} className="continue-reading link-text--green"
				target="_blank" 
				rel="noopener noreferrer"
			>Listen to the story or read along
			</a>}

			<a href="#top" className="back-to-top link-text--green">Back to top ↑</a>
		</div>
		</>
	)
}


				// href={props.item.moreAHref} className="continue-reading link-text link-text--italic"
import React from 'react'
import './index.css';
import Header from "./components/Header.js"
import Footer from "./components/Footer"
import {
  Outlet,
} from "react-router-dom"


function App() {

  return (
    <div className="App container--content-all">
      <div className="container--content-except-footer">
        <Header className="header"/>
        <Outlet />
      </div>
      <Footer />
      
    </div>
  );
}

export default App;

  // return (
  //   <div className="App container--content-all">
  //     <div className="container--content-except-footer">
  //       <Header className="header"/>
  //       <Outlet />
  //     </div>
  //     <Footer />
      
  //   </div>
  // );
import React from 'react'

import {
  NavLink,
} from "react-router-dom"

function Header(){
	
	let navLinkStyles = ({isActive}) => {

		const underline = {
			borderBottom: 'solid 1px var(--white90)',
			paddingBottom: '.3rem',
		}

		const noUnderline = {
			borderBottom: 'none',
			paddingBottom: '0',
		}
		
		return isActive? underline : noUnderline
	}

	

	let btnStyles = ({isActive}) => {return {boxShadow: isActive? 'var(--white-box-shadow)' : 'none'}
	}

	return (
		<header className="header">
			<h1 className="logo-title">Haldane B. Doyle</h1>
			<p className="logo-subtitle">Science Fiction for an Unexpected Future</p>
			<nav className="nav">
				<ul className="flex-row">
					<li><NavLink 
						className="link-text underline" 
						to="/" end
						style = {navLinkStyles}
						>Home
					</NavLink></li>
					<li><NavLink
						className="link-text" to="writing"
						style = {navLinkStyles}
						>Writing
					</NavLink></li>
					<li><NavLink
					 	className="link-text" to="media"
						style = {navLinkStyles}
						>Media
					</NavLink></li>
					<li><NavLink
						className="link-text" to="shop"
						style = {navLinkStyles}
						>Shop
					</NavLink></li>
					<li><NavLink
					 	className="link-text" to="blog"
						style = {navLinkStyles}
						>Blog
					</NavLink></li>
					<li><NavLink
					 	className="link-text" to="about"
						style = {navLinkStyles}
						>About
					</NavLink></li>
					<li><NavLink
					 	className="btn--contrast link-text" 
						to="get-updates"
						style = {btnStyles}
						>Sign up free
					</NavLink></li>
				</ul>
			</nav>
		</header>
	)
	
}

export default Header